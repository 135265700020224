html, body {
  margin:0px;
  height:100%;
}

.halo {
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .halo {
    height: 100%;
  }
}

.logo {
  width: 200px;
  border: black;
  border-style: solid;
  border-width: 10px;
  border-radius: 20px;
}

.gcse-search {
  width: 500px;
}

.s1 {
  text-align: center;
}

.main {
  font-family: 'Nunito', sans-serif;
}


/* Mode: */

.light {
  background-color: white;  
  color: black;
}

.lightNoText {
  background-color: white;  
}

.dark { 
  background-color: #252528;
  color: white;
}

.darkNoText { 
  background-color: #252528;
}

/* Nav: */

.d-inline-block {
  padding: 10px;
}

.nav {
  width: fluid;
}

.iconSize {
  height: 100px;
}


/* Calculator */

.icon {
  width: 150px;
  margin-bottom: 10px;
}

.cardText {
  color: black;
  text-align: center;
}

.total {
  border-style: solid;
  border-width: 10px;
  border-radius: 15px;
  width: fluid;
  background-color: rgb(75, 125, 226);
  text-align: center;  
  margin: 0 auto;
  margin-bottom: 10px;
}

.icon2 {
  width: 50px;
  margin: 5px;
}

.number {
  background-color: white;
  color: rgb(0, 0, 0);
  border-width: 5px;
  border-radius: 15px;
  border-color:  white;
  border-style: solid;
  width: 150px;
  text-align: center;  
  margin: 0 auto;
  margin-bottom: 10px;
}


/* Footer: */

.footer {
  background-color: rgb(27, 27, 27);
  color: white;
  padding: 10px;
  width:100%;
  position: fixed;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .footer {
    background-color: rgb(27, 27, 27);
    color: white;
    padding: 10px;
    width:100%;
    position: relative;
    bottom: 0;
  }
}

/* CardMain: */

.break {
  visibility: hidden;
}


/* Titles: */

.title {
  text-align: center;
  font-size: 60px;
  font-family: 'Nanum Brush Script', cursive;
}

.title2 {
  text-align: center;
  font-size: 30px;
  font-family: 'Nanum Brush Script', cursive;
}

.title2Alt {
  text-align: center;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
}


/* Toggle Switch Styling: */

.game {
  margin: 0 auto;
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}